<template>
  <fw-layout
    back-to-enable
    :back-to="'-1'"
    :right-sidebar-expanded="true"
    :right-sidebar-width="''"
    :main-sidebar="false"
    :footer="false"
    :connected-users="connectedUsers"
    wide
    full
    inner-content-scrollable
    paddingless
  >
    <template #main-content>
      <PanelDocument
        :page-key="pageKey"
        :edit-mode="editMode"
        :prop-version-key="versionKey"
        :context="'myuc'"
        @change-version="goToVersion"
        @open="openDoc($event, editMode)"
        @edit="openDoc($event, true)"
      />
    </template>
  </fw-layout>
</template>

<script>
import PanelDocument from '@/fw-modules/fw-core-vue/pages/components/panels/PanelDocument'

export default {
  components: {
    PanelDocument
  },
  computed: {
    connectedUsers() {
      return this.$store.getters.getConnectedUsers.users
    },

    pageKey() {
      return this.$route.params.key
    },

    versionKey() {
      return this.$route.params.version
    },

    editMode() {
      return this.$route.meta.edit
    }
  },

  mounted() {
    if (process.env.NODE_ENV == 'production') {
      this.$router.push({ name: 'manage' })
    }
  },

  methods: {
    openDoc(page, edit) {
      console.log('openDoc :>> ', page)
      if (edit) {
        this.$router.push({
          name: 'manage-docs-editor',
          params: { key: page.key }
        })
        return
      }
      this.$router.push({
        name: 'manage-docs-viewer',
        params: { key: page.key }
      })
    },

    goToVersion(newVersion) {
      if (this.editMode) {
        this.$router.push({
          name: 'manage-docs-editor-with-version',
          params: { key: this.pageKey, version: newVersion.key }
        })
        return
      }
      this.$router.push({
        name: 'manage-docs-viewer-with-version',
        params: { key: this.pageKey, version: newVersion.key }
      })
    }
  }
}
</script>
